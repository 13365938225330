import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import axios from "axios";
import { Avatar, Button, Popconfirm, Tooltip, message } from "antd";
import DrawerComp from "../components/Drower/Drawer";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import open from "../images/open.png";
import close from "../images/close.png";

export default function Branches() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Profile",
      dataIndex: "branch_img",
      key: "branch_img",
    },
    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      dataIndex: "is_closed",
      key: "is_closed",
    },
    {
      dataIndex: "setting",
      key: "setting",
    },
    {
      title: "",
      dataIndex: "view",
      key: "",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData, setSocialMediaList,    setDeliveryPartners,} = useDrawer();
  const { token } = useSelector((state) => state.auth);

  // ========================================== Fetch Pakage Data ========================
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        setTotalData(response.data.total);
        const updatedData = response.data.list.map((item) => ({
          ...item,
          branch_img: (
            <Avatar
              style={{ backgroundColor: randomColor }}
              src={item.branch_img !== null ? item.branch_img : null}
              alt={item.name}
            >
              {item.name.charAt(0).toUpperCase()}
            </Avatar>
          ),
          created_at: moment(item.created_at).format("llll"),
          is_closed: (
            <Popconfirm
              title="Branch Status"
              description="You want to change status ?"
              onConfirm={() => changeClosed(item.id, item.is_closed == 1 ? 0 : 1)}
              okText="Yes"
              cancelText="No"
            >
              <img
                style={{ width: "80px" }}
                src={item.is_closed == 1 ? open : close}
                alt={item.name}
              />
            </Popconfirm>
          ),
          view: (
            <Tooltip title="View Branch Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerUpdate(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
          setting: (
            <Tooltip title="Other Setting" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerSetting(item)}
              >
                Setting
              </Button>
            </Tooltip>
          ),
          remove: (
            <Popconfirm
              title="Remove Branch"
              description="Are you sure to remove this branch?"
              onConfirm={() => confirm(item.id)}
              okText="Yes"
              cancelText="No"
            >
              {" "}
              <Button danger>
                {" "}
                <FaTrashAlt />
              </Button>
            </Popconfirm>
          ),
        }));
        setFetchList(updatedData);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ===================== Change Closed ================
  const changeClosed = async(id, status) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("token", token);
      formData.append("is_closed", status)
      formData.append("action", "update");

      messageApi.open({
        key,
        type: "loading",
        content: "Loading...",
      });
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php",
        formData
      );
      if (response.data) {
        messageApi.open({
          key,
          type: "success",
          content: "Status Updated!",
        });
      }
    } catch (error) {
      messageApi.open({
        key,
        type: "error",
        content: "Status Update Failed!",
      });
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "employeeUpdate",
      title: "Branch Update",
      value: value,
    });
  };

  // ======================= Handle Drawer Setting =================================

  const handleDrawerSetting = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "branchSetting",
      title: "Branch Other Setting",
      value: value,
    });
    setSocialMediaList(value.social);
    setDeliveryPartners(value.partners)
  };

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "employeeAdd",
      title: "Branch Add",
      ID: null,
    });
  };

  // ====================================== Update Active Status ===========================
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  // ===================== Remove Employee =====================
  const confirm = async (ID) => {
    try {
      const formData = new FormData();
      formData.append("id", ID);
      formData.append("token", token);
      formData.append("action", "remove");

      messageApi.open({
        key,
        type: "loading",
        content: "Loading...",
      });
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php",
        formData
      );
      if (response.data) {
        messageApi.open({
          key,
          type: "success",
          content: "Removed Successfully!",
        });
      }
    } catch (error) {
      messageApi.open({
        key,
        type: "error",
        content: "Remov Failed!",
      });
      // Handle errors
      console.error("Error making POST request", error);
    }
  };
  return (
    <div>
      {contextHolder}
      <TitleComp
        name="Branch List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Branches"
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
