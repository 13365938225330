import "./App.css";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Categories from "./pages/catogery-list";
import React, { useEffect, useState } from "react";
import FoodItems from "./pages/food-list";
import Branches from "./pages/branch-list";
import Login from "./pages/login";
import LayoutContext from "./context/layout-context";
import NotFound from "./pages/404";
import Dashboard from "./pages/dashboard";
import { useSelector } from "react-redux";
import AccountDetails from "./pages/account-details";
import EnachDetail from "./pages/order-detail";
import UserDetail from "./pages/user-detail";
import { useSideBar } from "./context/nav-toggle";
import NotificationAlert from "./components/notificationInformDesign/NotificationAlert";
import Splashscreen from "./splashscree";

function App() {
  const { token } = useSelector((state) => state.auth);
  const { isOpen, setIsOpen } = useSideBar();
  const [isSticky, setIsSticky] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    setTimeout(() => {
      setShowSplash(false);
    }, 2000);
  }, []);

  const handleClickOutside = () => {
    // Clicked outside the left bar, hide it
    setIsOpen(true);
  };

  return (
    <>
      {isSticky && (
        <div
          className={`backgroundSideBar ${!isOpen ? "openSide" : ""}`}
          onClick={handleClickOutside}
        ></div>
      )}
      {showSplash ? (
        <Splashscreen />
      ) : (
        <HashRouter>
          {/* Routes with header and sidebar */}
          {/* {token ? ( */}
          {/* <LayoutContext> */}
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={
                token ? (
                  <LayoutContext>
                    <Branches />
                  </LayoutContext>
                ) : (
                  <Login />
                )
              }
            />
            <Route path="*" element={<NotFound />} />

            <Route
              path="/dashboard"
              element={
                <LayoutContext>
                  <Dashboard />
                </LayoutContext>
              }
            />
            <Route
              path="/categories"
              element={
                <LayoutContext>
                  <Categories />
                </LayoutContext>
              }
            />
            <Route
              path="/food-items"
              element={
                <LayoutContext>
                  <FoodItems />
                </LayoutContext>
              }
            />
            <Route
              path="/branches"
              element={
                <LayoutContext>
                  <Branches />
                </LayoutContext>
              }
            />
            <Route
              path="/account"
              element={
                <LayoutContext>
                  <AccountDetails />
                </LayoutContext>
              }
            />
            <Route
              path="/orders"
              element={
                <LayoutContext>
                  <EnachDetail />
                </LayoutContext>
              }
            />
            <Route
              path="/users"
              element={
                <LayoutContext>
                  <UserDetail />
                </LayoutContext>
              }
            />
            <Route
              path="/order-detail/:orderId"
              element={
                <LayoutContext>
                  <NotificationAlert />
                </LayoutContext>
              }
            />
          </Routes>
          {/* </LayoutContext> */}
        </HashRouter>
      )}
    </>
  );
}

export default App;
