import React, { useEffect, useState } from "react";
import { Alert, Button, InputNumber, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import { useFoodDetailMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";

export default function PackageUpdateForm() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "0",
      name: "no image",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);
  const [updateImage, setUpdateImage] = useState(false);
  const [foodDetail] = useFoodDetailMutation();
  const [imgError, setImgError] = useState({ message: null, status: null });

  // ================= Check Image Size ===================
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/avif" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/AVIF/WEBP file!");
      setImgError({
        message: "You can only upload JPG/PNG/AVIF/WEBP file!",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    const isLt2M = file.size / 1024 < 80;
    if (!isLt2M) {
      message.error("Image size larger that 80KB please re-upload");
      setImgError({
        message: "Image size larger that 80KB please re-upload",
        status: true,
      });
    } else {
      setImgError({
        message: null,
        status: false,
      });
    }
    return isJpgOrPng && isLt2M;
  };
  // ================= Submit Branch Form =====================

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("Name", values.name);
      formData.append("price", values.price);
      formData.append("foodType", values.foodType);
      if (updateImage) {
        formData.append("Image", fileList[0].originFileObj);
      } else {
        formData.append("Image", null);
      }
      formData.append("Description", values.description);
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("action", "update");
      formData.append("token", token);

      setLoading(true);

      const response = await foodDetail(formData);

      if (response.data) {
        setLoading(false);
        form.resetFields();
        toggleDrawer();
        message.success("Updated success!");
        setImgError({ message: false, status: null });
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setUpdateImage(true);
  };

  console.log(fileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.id,
        name: drawerData.value.Name,
        price: drawerData.value.price,
        foodType: drawerData.value.foodType,
        description: drawerData.value.Description
      });

      fileList[0].url = drawerData.value.branch_img;
      fileList[0].thumbUrl = drawerData.value.branch_img;
      setFileList([...fileList]);
    }
    // eslint-disable-next-line
  }, [drawerData]);

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        {imgError.status && <Alert message={imgError.message} type="error" />}
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="cat_image"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif, .webp"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Food Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Food Name" />
        </Form.Item>
        <Form.Item
          name="price"
          label="Enter Price "
          style={{
            display: "inline-block",
            margin: "3px 4px",
            width: "calc(50% - 8px)",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber addonBefore="+" addonAfter="$" defaultValue={0} />
        </Form.Item>
        <Form.Item
          name="foodType"
          label="Select Type "
          style={{
            display: "inline-block",
            margin: "3px 4px",
            width: "calc(50% - 8px)",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Selecte Food Type"
            options={[
              {
                value: "veg",
                label: "Veg",
              },
              {
                value: "nonVeg",
                label: "Non Veg",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description "
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={100}
            placeholder="Food Description"
            style={{
              height: 80,
              resize: "none",
            }}
          />
        </Form.Item>
        {/* <Form.Item
          name="branch"
          label="Select Branch "
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            loading={loading}
            placeholder="Select Branch"
            onSelect={onBranchSelect}
          >
            {branchList &&
              branchList.length > 0 &&
              branchList.map((value) => (
                <Select.Option label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          name="category"
          label="Select Category "
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Category"
            filterOption={filterOption}
          >
            {categoryList &&
              categoryList.length > 0 &&
              categoryList.map((value) => (
                <Select.Option label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          name="address"
          label="Category Description"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            style={{
              height: 100,
              resize: "none",
            }}
            placeholder="Address"
          />
        </Form.Item> */}

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
