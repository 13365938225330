import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import SideBarContext from "./context/nav-toggle";
import DrawerContext from "./context/drawer";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root"));
function renderReactDom() {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SideBarContext>
          <DrawerContext>
            <App />
          </DrawerContext>
        </SideBarContext>
      </Provider>
    </React.StrictMode>
  );
}

const handleKeyStore = async (token) => {
  const formData = new FormData();
  formData.append("notificationToken", token);
  formData.append("device", "android");

  const response = await axios.post(
    "https://indiandelightms.com/api/notification.php",
    formData
  );

  if (response.data) {
    localStorage.setItem("storeToken", true);
  }
};

function firebasePlugin() {
  async function requestingPermission() {
    try {
      // Request permission for local notifications
      const granted = await new Promise((resolve) => {
        cordova.plugins.notification.local.requestPermission((result) => {
          resolve(result);
        });
      });

      if (granted) {
        console.log("Local notification permission granted");
      } else {
        navigator.notification.alert(
          "Please allow notification permission in app setting or you unable received any notification.",
          "",
          "Notification Permission.",
          "OK"
        );
      }
    } catch (error) {
      // Log the error to the console
      console.error(
        "Error in requesting local notification permission:",
        error
      );
    }
  }

  requestingPermission();

  window.FirebasePlugin.getToken(
    function (token) {
      // save this server-side and use it to push notifications to this device
      console.log(token);
      handleKeyStore(token);
    },
    function (error) {
      console.error(error);
    }
  );

  // Get notified when a token is refreshed
  window.FirebasePlugin.onTokenRefresh(
    function (token) {
      // save this server-side and use it to push notifications to this device
      console.log("Refresh to get new token: " + token);
    },
    function (error) {
      alert(error);
    }
  );

  // Get notified when the user opens a notification
  window.FirebasePlugin.onMessageReceived(
    function (message) {
      console.log(message);
      if (message.messageType === "notification") {
        // Show notification or perform other actions
        showNotification(message);
        // alert(message.title + " for more detail check in orders page");
        // Check if the notification is tapped and the app is in the foreground
        // if (message.tap) {
        //   // Additional action when notification is tapped
        //   navigator.notification.alert(message.body, "", message.title, "OK")
        // }
      }
    },
    function (error) {
      console.error(error);
    }
  );

  function showNotification(data) {
    try {
      const localNotificationPlugin = cordova?.plugins?.notification?.local;

      if (localNotificationPlugin) {
        localNotificationPlugin.schedule({
          title: data.title,
          text: data.body,
          smallIcon: "res://app_icon.png",
          vibrate: true,
          icon: data.icon,
          foreground: !data.tap, // Show only if not tapped (background scenario)
        });

        // Notification scheduled successfully
        navigator.notification.alert(data.body, "", data.title, "OK");
      }
    } catch (error) {
      // Log the error to the console
      console.error("Error in handleLocalNotifications:", error);
    }
  }
  renderReactDom();
}

if (window.cordova) {
  document.addEventListener("deviceready", firebasePlugin, false);
} else {
  renderReactDom();
}
