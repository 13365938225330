import React, { useEffect, useState } from "react";
import TitleComp from "../components/Title/title";
import axios from "axios";
import DrawerComp from "../components/Drower/Drawer";
import { useDrawer } from "../context/drawer";
import { useSelector } from "react-redux";
import CardList from "../components/CardList/CardList";
import { Spin } from "antd";

export default function Categories() {
  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(20);
  const [totalData, setTotalData] = useState(null);
  const [branch, setBranch] = useState("default");
  const [search, setSearch] = useState("");

  // =========================================== Drawer Context =========================

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchData = async (page, limit, search, branch) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/catogery-details.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "&branch=" +
          branch +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        setFetchList(response.data.list);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, search, branch === "default" ? "" : branch);
    // eslint-disable-next-line
  }, [pageComp, limitComp, search, branch]);

  // ======================= Handle Drawer Add =================================

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "clientAdd",
      title: "catogery Add",
      ID: null,
    });
  };

  return (
    <div>
      <TitleComp
        name="Catogery List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Catogery"
      />
      <Spin spinning={loading}>
        <CardList
          limitComp={limitComp}
          pageComp={pageComp}
          setPageComp={setPageComp}
          totalData={totalData}
          setLimitComp={setLimitComp}
          loading={loading}
          fetchData={fetchData}
          fetchList={fetchList}
          setBranch={setBranch}
          branch={branch}
          setSearch={setSearch}
          search={search}
        />
      </Spin>
      <DrawerComp />
    </div>
  );
}
