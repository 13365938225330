import {
  Avatar,
  Button,
  List,
  Pagination,
  Popconfirm,
  Select,
  message,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useState } from "react";
import { HiPencil, HiRefresh } from "react-icons/hi";
import { useDrawer } from "../../context/drawer";
import axios from "axios";
import EmptyComp from "../Empty/EmptyComp";
import { Tooltip } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { useFoodDetailMutation } from "../../services/userAuthAPI";
import { useSelector } from "react-redux";

export default function FetchCardList({
  loading,
  totalData,
  limitComp,
  pageComp,
  setLimitComp,
  setPageComp,
  fetchData,
  fetchList,
  setCategory,
  setBranch,
  branch,
  setSearch,
  category,
  search,
}) {
  const { drawerData, setDrawerData } = useDrawer();
  const [branchLoading, setBranchLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const handleChange = (page, pageSize) => {
    setPageComp(page);
    setLimitComp(pageSize);
  };
  const [foodDetail] = useFoodDetailMutation();
  const { token } = useSelector((state) => state.auth);
  const [categoryList, setCategoryList] = useState();

  const handleSubmit = (value, event) => {
    setSearch(value);
  };
  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "packageUpdate",
      title: "Update Data",
      value: value,
    });
  };

  // ====================== Branch List ========================
  const fetchDataBranch = async (page, limit, search) => {
    try {
      setBranchLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php?action=fetchWithCategory&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          ""
      );
      if (response.data) {
        setBranchLoading(false);
        setBranchList(response.data.list);
      }
    } catch (error) {
      // Handle errors
      setBranchLoading(false);
      console.error("Error making POST request", error);
    }
  };

  //   ==================== Filter according Catogery ==================

  const handleChangeBranch = (id) => {
    setBranch(id === "default" ? "" : id);
    if (id !== "default") {
      const categoriesList = branchList.filter((value) => value.id === id);
      setCategoryList(categoriesList[0].categories);
    }
  };

  // ========================= Remove Food Item =====================
  const removeCategory = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("action", "remove");
      formData.append("token", token);
      const response = await foodDetail(formData);

      if (response.data) {
        message.success("Removed Successfully!");
      }
    } catch (error) {
      message.error("Update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  // =============== onSearch Category ===================
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChangeCategory = (id) => {
    setCategory(id === "default" ? "" : id);
  };
  return (
    <div>
      <div className="headerTitle d-sm-flex justify-content-sm-between">
        <div className="filterList d-lg-flex">
          <Search
            style={{ marginBottom: "30px", width: "100%", maxWidth: "350px" }}
            placeholder="Search"
            loading={loading}
            enterButton
            onSearch={handleSubmit}
          />
          <div className="d-sm-flex justify-content-start">
            <Select
              loading={branchLoading}
              style={{
                width: "100%",
                maxWidth: 350,
                marginBottom: "20px",
              }}
              onChange={handleChangeBranch}
              onClick={() => fetchDataBranch(0, 10, "")}
              placeholder="Select Branch"
            >
              <Select.Option label="Default" value="default">
                Default
              </Select.Option>
              {branchList &&
                branchList.length > 0 &&
                branchList.map((value, i) => (
                  <Select.Option key={i} label={value.name} value={value.id}>
                    {value.name}
                  </Select.Option>
                ))}
            </Select>
            <Select
              loading={branchLoading}
              style={{
                width: "100%",
                maxWidth: 350,
                marginBottom: "20px",
              }}
              onChange={handleChangeCategory}
              filterOption={filterOption}
              placeholder="Select Category"
              disabled={branch === "default"}
            >
              <Select.Option label="Default" value="default">
                Default
              </Select.Option>
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((value, i) => (
                  <Select.Option key={i} label={value.name} value={value.id}>
                    {value.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>

        <Button
          type="primary"
          loading={loading}
          icon={<HiRefresh />}
          style={{ marginBottom: "30px" }}
          onClick={() =>
            fetchData(pageComp, limitComp, search, branch, category)
          }
        >
          Refresh Data
        </Button>
      </div>

      <div className="cardList">
        <div className="row">
          {fetchList && fetchList.length > 0 ? (
            fetchList.map((value, i) => (
              <div className="col-md-4 mb-4" key={i}>
                <div className="CardListData">
                  <List>
                    <List.Item key={value.slug}>
                      <List.Item.Meta
                        avatar={<Avatar src={value.Image} alt={value.Name} />}
                        title={
                          <div
                            className="d-flex justify-content-between"
                            style={{ textTransform: "capitalize" }}
                          >
                            <strong className="d-flex justify-content-start align-items-center">
                              {value.Name}
                              <span
                                className={`${value.foodType} typeIcon`}
                              ></span>
                            </strong>
                            <strong>
                              {/* price{" "} */}
                              <span style={{ color: "#808080" }}>
                                ${value.price}
                              </span>
                            </strong>
                          </div>
                        }
                        description={
                          <div
                            className="d-flex flex-column"
                            style={{ textTransform: "capitalize" }}
                          >
                            <div>{value.Description}</div>
                            {/* <div>{value.categName}</div> */}
                          </div>
                        }
                      />
                    </List.Item>
                  </List>
                  <div className="buttonGroup mx-5 mb-2">
                    <Tooltip placement="bottom" title="Edit">
                      <Button
                        onClick={() => handleDrawerUpdate(value)}
                        type="primary"
                        shape="circle"
                      >
                        <HiPencil />
                      </Button>
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                      title="Remove Employee"
                      description="Are you sure to remove this Food?"
                      onConfirm={() => removeCategory(value.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip placement="bottom" title="Remove">
                        <Button type="primary" shape="circle">
                          <BsTrashFill />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyComp />
          )}
        </div>
      </div>
      <Pagination
        style={{ marginTop: "30px" }}
        total={totalData}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={limitComp}
        current={pageComp}
        onChange={handleChange}
      />
    </div>
  );
}
