import React, { useEffect, useRef, useState } from "react";
import { Layout, Menu } from "antd";
import { useSideBar } from "../../context/nav-toggle";
import {
  BiFoodTag,
  BiLogOut,
  // BiSolidDashboard,
  BiSolidFoodMenu,
} from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MenuItem from "antd/es/menu/MenuItem";
import { MdAccountCircle, MdFastfood, MdFoodBank } from "react-icons/md";
import { getProfile, removeToken } from "../../services/localStorageServices";
const { Sider } = Layout;

export default function Sidebar() {
  const { isOpen } = useSideBar();
  const { profile } = getProfile();
  const [isSticky, setIsSticky] = useState(true);
  const navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url);
  };

  const logout = () => {
    removeToken();
    navigate("/login");
  };

  useEffect(() => {
    // Check screen size and print to console if less than 767 pixels
    if (window.innerWidth <= 767) {
      setIsSticky(false);
    }else{
      setIsSticky(true);
    }
  }, []);


  return (
    <>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: isSticky ? "sticky" : "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 100,
        }}
        trigger={null}
        collapsed={isOpen}
        collapsedWidth={isSticky ? 80 : 0}
      >
        <div
          className="demo-logo-vertical"
          style={{
            padding: "10px",
            textAlign: "center",
            color: "#fff",
            fontSize: "1.3rem",
          }}
        >
          Admin Panel
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          {/* <MenuItem
          key="1"
          icon={<BiSolidDashboard />}
          onClick={() => navigateToPage("/dashboard")}
        >
          Dashboard
        </MenuItem> */}
          <MenuItem
            key="1"
            icon={<MdFoodBank />}
            onClick={() => navigateToPage("/branches")}
          >
            Branches
          </MenuItem>
          <MenuItem
            key="2"
            icon={<BiFoodTag />}
            onClick={() => navigateToPage("/categories")}
          >
            Food Category
          </MenuItem>
          <MenuItem
            key="3"
            icon={<MdFastfood />}
            onClick={() => navigateToPage("/food-items")}
          >
            Food Items
          </MenuItem>
          <MenuItem
            key="4"
            icon={<FaUsersCog />}
            onClick={() => navigateToPage("/users")}
          >
            Users
          </MenuItem>
          <MenuItem
            key="5"
            icon={<BiSolidFoodMenu />}
            onClick={() => navigateToPage("/orders")}
          >
            Orders
          </MenuItem>
          {profile.type === "admin" ? (
            <MenuItem
              key="6"
              icon={<MdAccountCircle />}
              onClick={() => navigateToPage("/account")}
            >
              Accounts
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={logout}
            className="logoutBtn"
            key="7"
            icon={<BiLogOut />}
            // onClick={() => navigateToPage("/bookings")}
          >
            Log Out
          </MenuItem>
        </Menu>
      </Sider>

    </>
  );
}
