import React, { useEffect, useState } from "react";
import { Alert, Button, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import { useCategoryDetailMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

// ================= Image Validation ==================

export default function AddBranches() {
  const { toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  // const [fileList, setFileList] = useState(null);
  const [categoryDetail] = useCategoryDetailMutation();
  const [branchList, setBranchList] = useState();
  const [imgError, setImgError] = useState({ message: null, status: null });

  // ================= Check Image Size ===================
  // const beforeUpload = (file) => {
  //   const isJpgOrPng =
  //     file.type === "image/jpeg" ||
  //     file.type === "image/png" ||
  //     file.type === "image/avif";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG/AVIF file!");
  //     setImgError({
  //       message: "You can only upload JPG/PNG file!",
  //       status: true,
  //     });
  //   } else {
  //     setImgError({
  //       message: null,
  //       status: false,
  //     });
  //   }
  //   const isLt2M = file.size / 1024 < 300;
  //   if (!isLt2M) {
  //     message.error("Image size larger that 300KB please re-upload");
  //     setImgError({
  //       message: "Image size larger that 300KB please re-upload",
  //       status: true,
  //     });
  //   } else {
  //     setImgError({
  //       message: null,
  //       status: false,
  //     });
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  // ================= Submit Branch Form =====================

  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("Name", values.name);
      // if (fileList) {
      //   formData.append("Image", fileList.originFileObj);
      // } else {
        formData.append("Image", null);
      // }
      formData.append("Description", null);
      formData.append("branch_id", values.branch);
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("action", "add");
      formData.append("token", token);

      setLoading(true);

      const response = await categoryDetail(formData);

      if (response.data) {
        setLoading(false);
        if (response.data.status === "Failed Name") {
          message.error(response.data.message);
          setImgError({ message: response.data.message, status: true});
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Submit success!");
          setImgError({ message: false, status: null });
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Add failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  // const handleChange = (file) => setFileList(file.file);

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  // ====================== Branch List ========================

  const fetchData = async (page, limit, search) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          ""
      );
      if (response.data) {
        setLoading(false);
        setBranchList(response.data.list);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(0, 10, "");
  }, []);

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {imgError.status && <Alert message={imgError.message} type="error" />}
        {/* <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="cat_image"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item> */}

        <Form.Item
          name="name"
          label="Category Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Name" />
        </Form.Item>

        <Form.Item
          name="branch"
          label="Select Branch "
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select Branch">
            {branchList &&
              branchList.length > 0 &&
              branchList.map((value, i) => (
                <Select.Option key={i} label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="address"
          label="Category Description"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            style={{
              height: 100,
              resize: "none",
            }}
            placeholder="Address"
          />
        </Form.Item> */}

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
