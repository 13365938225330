import React, { useState } from "react";
import { HiPencil, HiRefresh } from "react-icons/hi";
import { useDrawer } from "../../context/drawer";
import axios from "axios";
import EmptyComp from "../Empty/EmptyComp";
import { Tooltip } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { useCategoryDetailMutation } from "../../services/userAuthAPI";
import { useSelector } from "react-redux";
import { Button, List, Pagination, Popconfirm, Select, message } from "antd";
import Search from "antd/es/input/Search";

export default function CardList({
  loading,
  totalData,
  limitComp,
  pageComp,
  setLimitComp,
  setPageComp,
  fetchData,
  fetchList,
  setBranch,
  branch,
  setSearch,
  search,
}) {
  const { drawerData, setDrawerData } = useDrawer();
  const [branchLoading, setBranchLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const handleChange = (page, pageSize) => {
    setPageComp(page);
    setLimitComp(pageSize);
  };
  const [categoryDetail] = useCategoryDetailMutation();
  const { token } = useSelector((state) => state.auth);

  const handleSubmit = (value, event) => {
    fetchData(pageComp, limitComp, value, "");
  };
  // ======================= Handle Drawer Update =================================

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "clientUpdate",
      title: "Update Data",
      value: value,
    });
  };

  // ====================== Branch List ========================
  const fetchDataBranch = async (page, limit, search) => {
    try {
      setBranchLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/branch-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          ""
      );
      if (response.data) {
        setBranchLoading(false);
        setBranchList(response.data.list);
      }
    } catch (error) {
      // Handle errors
      setBranchLoading(false);
      console.error("Error making POST request", error);
    }
  };

  // ========================= Remove Food Item =====================
  const removeCategory = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("action", "remove");
      formData.append("token", token);
      const response = await categoryDetail(formData);

      if (response.data) {
        message.success("Removed Successfully!");
      }
    } catch (error) {
      message.error("Update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  return (
    <div>
      <div className="headerTitle d-lg-flex justify-content-between">
        <div className="d-sm-flex justify-content-lg-start justify-content-between">
          <Search
            style={{
              marginBottom: "30px",
              width: "100%",
              minWidth: "250px",
              maxWidth: "350px",
            }}
            placeholder="Search"
            loading={loading}
            value={search}
            enterButton
            onSearch={(value) => setSearch(value)}
          />
          <Select
            loading={branchLoading}
            style={{
              width: "100%",
              width: 350,
              marginBottom: "20px",
            }}
            value={branch}
            onSelect={(value) => setBranch(value)}
            onClick={() => fetchDataBranch(0, 10, "")}
            placeholder="Select Branch"
          >
            <Select.Option label="Default" value="default">
              Default
            </Select.Option>
            {branchList &&
              branchList.length > 0 &&
              branchList.map((value, i) => (
                <Select.Option key={i} label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </div>

        <Button
          type="primary"
          loading={loading}
          icon={<HiRefresh />}
          style={{ marginBottom: "30px" }}
          onClick={() => fetchData(pageComp, limitComp, "", "")}
        >
          Refresh Data
        </Button>
      </div>

      <div className="cardList">
        <div className="row">
          {fetchList && fetchList.length > 0 ? (
            fetchList.map((value, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={i}>
                <div className="CardListData">
                  <List>
                    <List.Item key={value.slug}>
                      <List.Item.Meta
                        // avatar={<Avatar src={value.Image} alt={value.Name} />}
                        title={value.Name}
                        description={value.branchName}
                      />
                    </List.Item>
                  </List>
                  <div className="buttonGroup mx-2 mb-2">
                    <Tooltip placement="bottom" title="Edit">
                      <Button
                        onClick={() => handleDrawerUpdate(value)}
                        type="primary"
                        shape="circle"
                      >
                        <HiPencil />
                      </Button>
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                      title="Remove Employee"
                      description="Are you sure to remove this Category?"
                      onConfirm={() => removeCategory(value.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip placement="bottom" title="Remove">
                        <Button type="primary" shape="circle">
                          <BsTrashFill />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyComp />
          )}
        </div>
      </div>
      <Pagination
        style={{ marginTop: "30px" }}
        total={totalData}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={limitComp}
        current={pageComp}
        onChange={handleChange}
      />
    </div>
  );
}
