import React, { useEffect, useState } from "react";
import { Alert, Button, Select, Upload } from "antd";
import { Form, Input, message, Space } from "antd";
import { useDrawer } from "../../../context/drawer";
import "react-quill/dist/quill.snow.css";
// import { PlusOutlined } from "@ant-design/icons";
import { useCategoryDetailMutation } from "../../../services/userAuthAPI";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
// import axios from "axios";

export default function UpdateForm() {
  const { drawerData, toggleDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "0",
      name: "no image",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);
  // const [updateImage, setUpdateImage] = useState(false);
  const [categoryDetail] = useCategoryDetailMutation();
  const [imgError, setImgError] = useState({ message: null, status: null });
  const { token } = useSelector((state) => state.auth);

  // ================= Check Image Size ===================
  // const beforeUpload = (file) => {
  //   const isJpgOrPng =
  //     file.type === "image/jpeg" ||
  //     file.type === "image/png" ||
  //     file.type === "image/avif";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG/AVIF file!");
  //     setImgError({
  //       message: "You can only upload JPG/PNG file!",
  //       status: true,
  //     });
  //   } else {
  //     setImgError({
  //       message: null,
  //       status: false,
  //     });
  //   }
  //   const isLt2M = file.size / 1024 < 300;
  //   if (!isLt2M) {
  //     message.error("Image size larger that 300KB please re-upload");
  //     setImgError({
  //       message: "Image size larger that 300KB please re-upload",
  //       status: true,
  //     });
  //   } else {
  //     setImgError({
  //       message: null,
  //       status: false,
  //     });
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("Name", values.name);
      // if (updateImage) {
        formData.append("Image", null);
      // }
      formData.append("order_category", values.order_category);
      formData.append("action", "update");
      formData.append("slug", values.name.toLowerCase().split(" ").join("-"));
      formData.append("token", token);

      setLoading(true);
      const response = await categoryDetail(formData);

      if (response.data) {

        setLoading(false);
        if (response.data.status === "Failed Name") {
          message.error(response.data.message);
          setImgError({ message: response.data.message, status: true});
        } else {
          form.resetFields();
          toggleDrawer();
          message.success("Update success!");
          setImgError({ message: false, status: null });
        }
      }
    } catch (error) {
      setLoading(false);
      message.error("Update failed!");
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    if (drawerData.value !== null) {
      form.setFieldsValue({
        id: drawerData.value.id,
        name: drawerData.value.Name,
        order_category: drawerData.value.order_category
      });

      fileList[0].url = drawerData.value.Image;
      fileList[0].thumbUrl = drawerData.value.Image;
      setFileList([...fileList]);
    }
    // eslint-disable-next-line
  }, [drawerData]);

  // --------------- Image Upload Functions ==============
  // const handleChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  //   setUpdateImage(true);
  // };
  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  return (
    <div className="drawerForm">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        {imgError.status && <Alert message={imgError.message} type="error" />}
        {/* <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="cat_image"
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            accept=".jpeg, .jpg, .png, .avif"
            maxCount={1}
          >
            {uploadButton}
          </Upload>
        </Form.Item> */}

        <Form.Item
          name="name"
          label="Category Name"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Name" />
        </Form.Item>
        <Form.Item
          name="order_category"
          label="Category Order"
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Category Order" />
        </Form.Item>
        {/* 
        <Form.Item
          name="branch"
          label="Select Branch "
          style={{
            margin: "3px 4px",
          }}
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select Branch">
            {branchList &&
              branchList.length > 0 &&
              branchList.map((value) => (
                <Select.Option label={value.name} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          name="address"
          label="Category Description"
          hasFeedback
          style={{
            margin: "3px 4px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            style={{
              height: 100,
              resize: "none",
            }}
            placeholder="Address"
          />
        </Form.Item> */}

        <Form.Item style={{ marginTop: "20px" }}>
          <Space>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button danger onClick={toggleDrawer}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
