import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { useDrawer } from "../../context/drawer";
import UpdateForm from "../Forms/Category/update-form";
import AddForm from "../Forms/Category/add-form";
import PackageUpdateForm from "../Forms/Food/update-food";
import PackageAddForm from "../Forms/Food/add-food";
import AddBranches from "../Forms/Branches/add-branches";
import UpdateBranches from "../Forms/Branches/update-branches";
import BookingView from "../Forms/Booking/booking-view";
import UpdateAccount from "../Forms/Account/update-form";
import AddAccount from "../Forms/Account/add-form";
import EnachView from "../Forms/eNach/order-view";
import OtherSetting from "../Forms/Branches/other-setting";

const DrawerComp = () => {
  const { drawerData, toggleDrawer } = useDrawer();
  const [listData, setListData] = useState(null);

  useEffect(() => {
    if (drawerData.value !== null) {
      setListData(drawerData.value);
    }
  }, [drawerData]);

  return (
    <>
      <Drawer
        title={drawerData.title}
        placement="right"
        width={drawerData.type === "enachCreate" ? 500 : 500}
        onClose={toggleDrawer}
        open={drawerData.isOpen}
      >
        {drawerData.type === "clientUpdate" ? (
          <UpdateForm listData={listData} />
        ) : null}

        {drawerData.type === "clientAdd" ? <AddForm /> : null}

        {drawerData.type === "packageUpdate" ? <PackageUpdateForm /> : null}
        {drawerData.type === "packageAdd" ? <PackageAddForm /> : null}
        {drawerData.type === "employeeAdd" ? <AddBranches /> : null}
        {drawerData.type ===  "employeeUpdate" ? <UpdateBranches /> : null}
        {drawerData.type === "bookingView" ? <BookingView /> : null }
        {drawerData.type === "accountUpdate" ? <UpdateAccount /> : null}
        {drawerData.type === "accountAdd" ? <AddAccount /> : null}
        {drawerData.type === "viewOrder" ? <EnachView /> : null}
        {drawerData.type === "branchSetting" ? <OtherSetting /> : null}
        
      </Drawer>
    </>
  );
};
export default DrawerComp;
