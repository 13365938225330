import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
// import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

export default function UserDetail() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Registred Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Login Type",
      dataIndex: "usertype",
      key: "usertype",
    },

    {
      title: "",
      dataIndex: "view",
      key: "",
    },
    {
      title: "",
      dataIndex: "remove",
      key: "",
    },
  ];
  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const { token } = useSelector((state) => state.auth);

  // ========================= Fetch eNach List ================================
  const fetchData = async (page, limit, search) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/user-detail.php?action=fetchUser&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item) => ({
          ...item,
          usertype: (
            <span style={{ textTransform: "capitalize" }}>{item.usertype}</span>
          ),
          created_at: moment(item.created_at).format("llll"),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  useEffect(() => {
    fetchData(pageComp, limitComp, "", "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  return (
    <div>
      <TitleComp name="Users Lists" totalData={10} type="Users" />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
