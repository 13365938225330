import React, { useEffect, useState } from "react";
import TableComp from "../components/table/tables";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button, Modal, Steps, Tooltip, message } from "antd";
import moment from "moment";

export default function EnachDetail() {
  const columns = [
    {
      title: "Order ID",
      dataIndex: "Order_id",
      key: "Order_id",
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Phone",
      dataIndex: "userPhone",
      key: "userPhone",
    },
    {
      title: "Tip",
      dataIndex: "tip",
      key: "tip",
    },
    {
      title: "Total Paid",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Booked at",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Pickup at",
      dataIndex: "pickup_time",
      key: "pickup_time",
    },

    {
      title: "Booking Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "",
      dataIndex: "view",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  // ========================= Fetch eNach List ================================

  const checkStatus = (status) => {
    if (status === "success") {
      return "green";
    } else if (status === "failed") {
      return "red";
    } else {
      return "orange";
    }
  };

  const fetchData = async (page, limit, search, branch) => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      setLoading(true);

      const response = await axios.post(
        "https://indiandelightms.com/api/order-detail.php?action=fetch&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search +
          "&branch=" +
          branch +
          "",
        formData
      );
      if (response.data) {
        setLoading(false);
        const updatedData = response.data.list.map((item, i) => ({
          ...item,
          created_at: moment(item.created_at).format("llll"),
          userPhone: "+1 "+item.userPhone,
          total_amount: (
            <div style={{ display: "flex", alignItems: "center" }}>
              $&nbsp;{item.total_amount}
            </div>
          ),
          tip: (
            <div style={{ display: "flex", alignItems: "center" }}>
              $&nbsp;{item.tip}
            </div>
          ),
          payment_status: item.payment_status ? (
            <span style={{ color: checkStatus(item.payment_status) }}>
              {item.payment_status}
            </span>
          ) : (
            <span style={{ color: "orange", textTransform: "capitalize" }}>
              pending
            </span>
          ),
          view: (
            <Tooltip title="View Order Details" placement="bottom">
              <Button
                className="otherButtons"
                onClick={() => handleDrawerView(item)}
              >
                View
              </Button>
            </Tooltip>
          ),
        }));
        setFetchList(updatedData);
        setTotalData(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
      console.error("Error making POST request", error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "", "");
    // eslint-disable-next-line
  }, [pageComp, limitComp]);

  // ======================= Handle Drawer Add =================================

  const handleDrawerView = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "viewOrder",
      title: "Order Details",
      value: value,
    });
  };

  return (
    <div>
      <TitleComp name="Order Lists" totalData={10} type="Order" />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
